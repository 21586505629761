import { getSession } from 'next-auth/client';
import { useState, useEffect } from 'react';

import Courses, { Course, VideoUser } from 'src/apiClient/courses';
import User, { CourseProgress as ApiCourseProgress, ModuleProgress, VideoProgress } from 'src/proxyClient/users';
import { getUserId } from 'src/common/authUtil';
import { CourseProgressStatusEnum } from '../../models/enums';

export interface CourseProgress {
  id: number;
  title: string;
  imageUrl: string;
  remainingTimeInMinutes: number;
  progressInPercentage: number; // number between 0 and 1
  status: CourseProgressStatusEnum;
  nextVideo?: {
    moduleId: number | string | null;
    videoId: number | string | null;
  }
}

export type ParsedVideo = VideoUser & {
  courseId: string;
  moduleId: string;
  moduleTitle: string;
  moduleIndex: number;
  numberOfBadgesRequired: number;
};

const calculateRemainingTimeInMinutes = (progress: ApiCourseProgress, course: Course): number => {
  const progressInMinutes = progress.progressInSeconds / 60;
  const totalTimeInMinutes = course.totalTimeInSeconds / 60;
  return Math.floor(totalTimeInMinutes - progressInMinutes);
};

const createCourseProgress = async (
  progress: ApiCourseProgress,
  courses: Readonly<Course[]>,
): Promise<CourseProgress> => {
  const courseId = courses.find((c) => c.id === progress.courseId)?.id;
  const course = await Courses.getCourse(courseId);
  return {
    title: course.title,
    imageUrl: course.imageUrl,
    remainingTimeInMinutes: calculateRemainingTimeInMinutes(progress, course),
    progressInPercentage: progress.progressPercentage,
    id: progress.courseId,
    status: progress.status,
  };
};

export const createProgressesList = async (
  progresses: ApiCourseProgress[],
  courses: Readonly<Course[]>,
): Promise<CourseProgress[]> => Promise.all(
  progresses.map((p) => createCourseProgress(p, courses)),
);

export const createProgress = (progress: ApiCourseProgress, course: Course): CourseProgress => ({
  title: course.title,
  imageUrl: course.imageUrl,
  remainingTimeInMinutes: calculateRemainingTimeInMinutes(progress, course),
  progressInPercentage: progress.progressPercentage,
  id: progress.courseId,
  status: progress.status,
  nextVideo: progress.nextVideo,
});

export const useCoursesProgress = (courses: Readonly<Course[]>):
[boolean, CourseProgress[], CourseProgress[]] => {
  const [loading, setLoading] = useState(true);
  const [dataAllCourses, setDataAllCourses] = useState<CourseProgress[]>([]);
  const [dataNotFinished, setDataNotFinished] = useState<CourseProgress[]>([]);

  useEffect(() => {
    const fetchProgress = async () => {
      setLoading(true);
      const session = await getSession();
      const progresses = await User.getUserCourses(getUserId(session));
      const aux = await createProgressesList(progresses, courses);
      setDataAllCourses(aux);
      setDataNotFinished(aux.filter(
        (p) => p.progressInPercentage !== 0 && p.progressInPercentage !== 1,
      ));

      setLoading(false);
    };
    fetchProgress();
  }, []);

  return [loading, dataAllCourses, dataNotFinished];
};

export const useCourseProgress = (course: Readonly<Course>): [boolean, CourseProgress] => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<CourseProgress>({} as CourseProgress);

  useEffect(() => {
    const fetchProgress = async () => {
      setLoading(true);
      const session = await getSession();
      const progress = await User.getUserCourseProgress(getUserId(session), course.id);
      setData(createProgress(progress, course));
      setLoading(false);
    };
    fetchProgress();
  }, []);

  return [loading, data];
};

export const useModulesProgress = (course: Readonly<Course>): [boolean, ModuleProgress[]] => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ModuleProgress[]>([] as ModuleProgress[]);

  useEffect(() => {
    const fetchProgress = async () => {
      setLoading(true);
      const session = await getSession();
      const progress = await User.getUserCourseProgress(getUserId(session), course.id);
      setData(progress.modulesProgress);
      setLoading(false);
    };
    fetchProgress();
  }, []);

  return [loading, data];
};

export const useVideoProgress = (videoProps: ParsedVideo): [boolean, VideoProgress] => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<VideoProgress>({} as VideoProgress);

  useEffect(() => {
    const fetchProgress = async () => {
      setLoading(true);
      const session = await getSession();
      const progress = await User.getUserCourseProgress(
        getUserId(session),
        parseInt(videoProps.courseId, 10),
      );
      const moduleProgress = progress.modulesProgress
        .find((module) => module.moduleId === parseInt(videoProps.moduleId, 10));
      const videoProgress = moduleProgress.videosProgress
        .find((video) => video.videoId === videoProps.videoId);
      setData(videoProgress);
      setLoading(false);
    };
    fetchProgress();
  }, [videoProps]);

  return [loading, data];
};

export default useCoursesProgress;
