export enum CourseProgressStatusEnum {
  NotStarted,
  InProgress,
  Finished,
}

export enum ModuleProgressStatusEnum {
  NotStarted,
  InProgress,
  Finished,
}

export enum VideoProgressStatusEnum {
  NotStarted,
  InProgress,
  Finished,
}

export enum Smiles{
  NoneSmile,
  SadSmile,
  NeutralSmile,
  HappySmile,
}
