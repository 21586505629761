/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';

import Slider from 'react-slick';

import ProgressBar from 'components/ProgressBar';
import Icon from 'components/Icon';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from 'styles/CoursesList.module.scss';

const slideConfig = {
  infinite: false,
  variableWidth: true,
};

export interface CoursesListProps {
  courses: Course[];
}

export interface Course {
  id: number;
  title: string;
  imageUrl: string;
  remainingTimeInMinutes: number;
  progressInPercentage: number; // number between 0 and 1
}

interface CourseCardProps extends Course {}

const MAX_CARDS_TO_SHOW_WHEN_NOT_EXPANDED = 4;

const CourseCard: React.FC<CourseCardProps> = (course) => (
  <div className={styles.courseCard}>
    <img src={course.imageUrl} alt={course.title} />

    <div className={styles.body}>
      <h3>{course.title}</h3>
      <p className={styles.remainingTime}>
        Faltam <strong>{course.remainingTimeInMinutes} minutos</strong>
      </p>
      <ProgressBar progressInPercentage={course.progressInPercentage} />
    </div>
  </div>
);

const CoursesListCarrousel: React.FC<CoursesListProps> = ({ courses }) => (
  <div
    data-cy="carrousel"
    className={styles.coursesListCarrouselWrapper}
  >
    <Slider {...slideConfig}>
      { courses.map((c) => (
        <a href={`/courses/${c.id}`} key={`car-card-${c.title}`}>
          <CourseCard {...c} />
        </a>
      )) }
    </Slider>
  </div>
);

const listAllOrFirstMaxCards = (courses: Course[], isCollapsed: boolean): Course[] => (
  isCollapsed ? courses.slice(0, MAX_CARDS_TO_SHOW_WHEN_NOT_EXPANDED) : courses
);

const CoursesListGrid: React.FC<CoursesListProps> = ({ courses }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <div
      data-cy="grid"
      className={styles.coursesListGridWrapper}
    >
      <div className={styles.coursesGrid}>
        {
          listAllOrFirstMaxCards(courses, isCollapsed).map((c) => (
            <a key={`course-card-${c.title}`} href={`/courses/${c.id}`}>
              <CourseCard {...c} />
            </a>
          ))
        }
      </div>
      {
        courses.length > MAX_CARDS_TO_SHOW_WHEN_NOT_EXPANDED && (
          <div className={styles.buttonLine}>
            <button
              type="button"
              className={styles.expandButton}
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              <Icon name={isCollapsed ? 'chevron-down' : 'chevron-up'} />
            </button>
          </div>
        )
      }
    </div>
  );
};

const CoursesList: React.FC<CoursesListProps> = ({ courses }) => (
  <>
    <CoursesListCarrousel courses={courses} />
    <CoursesListGrid courses={courses} />
  </>
);
export default CoursesList;
