import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getSession } from 'next-auth/client';
import { GetStaticProps } from 'next';
import Image from 'next/image';
import { isMobile } from 'react-device-detect';

import { sha256 } from 'js-sha256';

import CoursesClient, { Course } from 'src/apiClient/courses';
import CourseCard, { CourseCardProps } from 'components/CourseCard';
import CoursesList from 'components/CoursesList';
import DeviceComponent from 'components/DeviceComponent';
import Modal from 'components/Modal';
import Utils from 'src/infra/utils';

import useCoursesProgress from 'src/hooks/useCoursesProgress';
import useClickOutside from 'src/hooks/useClickOutside';

import styles from 'styles/Home.module.scss';
import bannerStyles from 'styles/Banner.module.scss';
import User from 'src/proxyClient/users';
import Drawing from 'src/apiClient/drawing';
import { CourseProgressStatusEnum } from 'models/enums';
import { getUserId } from 'src/common/authUtil';
import { get, del } from 'idb-keyval';
import ProposalProcessWarning from '@components/integrations/ProposalProcessWarning';
import BodyHeader from '../components/BodyHeader';

interface Props {
  courses: Course[];
}

type CourseWithProgress = Course & {
  status: CourseProgressStatusEnum;
};

type Option = CourseProgressStatusEnum | null;

function parseCourseProps(course: CourseWithProgress): CourseCardProps {
  let formatTrophyLabel = '';
  let iconImg = '/assets/green-coin.svg';
  if (course.numberOfBadgesRequired === 0) {
    formatTrophyLabel = 'Gratuito';
    iconImg = undefined;
  } else if (course.numberOfBadgesRequired === 1) {
    formatTrophyLabel = `${course.numberOfBadgesRequired} Moeda`;
  } else {
    formatTrophyLabel = `${course.numberOfBadgesRequired} Moedas`;
  }

  return {
    title: course.title,
    description: course.description,
    imgUrl: course.imageUrl,
    status: {
      label: formatTrophyLabel,
      iconImgUrl: iconImg,
    },
  };
}

const StatusFilterOptions = [
  { name: 'Ainda não feitos', value: CourseProgressStatusEnum.NotStarted },
  { name: 'Em andamento', value: CourseProgressStatusEnum.InProgress },
  { name: 'Concluídos', value: CourseProgressStatusEnum.Finished },
  { name: 'Todos', value: null },
];

export default function Home({ courses }: Props) {
  const [username, setUsername] = useState('usuário');
  const [inviteUserOrigin, setInviteUserOrigin] = useState(null);
  const [inviteDrawingOrigin, setInviteDrawingOrigin] = useState(null);
  const [
    isUserWithOpenedEmprestimoSimProposal,
    setIsUserWithOpenedEmprestimoSimProposal,
  ] = useState(false);

  const setUserWithOpenedEmprestimoSimProposal = () => {
    get('preAnalisisData').then((data) => {
      setIsUserWithOpenedEmprestimoSimProposal(!!data);
    });
  };

  useEffect(() => {
    async function readUsername() {
      const session = await getSession();

      if (session?.user?.name) {
        setUsername(session?.user?.name);
      }
    }
    readUsername();
    setUserWithOpenedEmprestimoSimProposal();
  }, []);

  const [selectedOption, setSelectedOption] = useState('Ainda não feitos');
  const [
    loading,
    coursesProgressAllCourses,
    coursesProgress,
  ] = useCoursesProgress(courses);
  const [coursesSaved, setCoursesSaved] = useState<CourseWithProgress[]>([]);
  const [displayedCourses, setDisplayedCourses] = useState<
  CourseWithProgress[]
  >([]);

  const router = useRouter();
  const [hasHomeWarning, setHasHomeWarning] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const wrapperRef = useClickOutside(setIsDropdownOpen);
  const toggleDropdown = (): void => setIsDropdownOpen(!isDropdownOpen);

  const handleFriendInviteBadge = async (inviteOrigin) => {
    const session = await getSession();
    await User.setUserFriendInviteBadge(getUserId(session), inviteOrigin);
    del('members_invite_origin');
  };

  const handleFriendInviteBadgeDrawing = async (
    inviteOrigin,
    currentInviteDrawingOrigin,
  ) => {
    const session = await getSession();
    await Drawing.registerDrawingTicket(
      inviteOrigin,
      currentInviteDrawingOrigin,
      getUserId(session),
    );
    del('members_invite_drawing');
  };

  useEffect(() => {
    get('members_invite_origin').then((inviteOriginLocalStorage) => {
      if (inviteOriginLocalStorage) {
        setInviteUserOrigin(inviteOriginLocalStorage);
      }
    });

    get('members_invite_drawing').then((inviteDrawingOriginStorage) => {
      if (inviteDrawingOriginStorage) {
        setInviteDrawingOrigin(inviteDrawingOriginStorage);
      }
    });

    const sendOfferEmail = async () => {
      const session = await getSession();
      const userProfileData = await User.getUserProfileInfo(getUserId(session));

      const nameArray = userProfileData.name.split(' ');
      const lastNameIndex = nameArray.length - 1;
      const leadRetargetingPayload = {
        contact: {
          email: userProfileData.email,
          phone: userProfileData.cellphone,
          hashedEmail: sha256(userProfileData.email),
          firstName: nameArray[0],
          lastName: nameArray[lastNameIndex],
          link: router.query.offerUrl,
          name: 'plusdin-membros-offers',
        },
        name: 'plusdin-membros-offers',
      };

      User.sendLeadRetargeting(leadRetargetingPayload);
    };

    if (router.query.offerUrl) {
      setHasHomeWarning(true);
      sendOfferEmail();
    }
  }, []);

  useEffect(() => {
    let invitedHandled = false;

    if (inviteUserOrigin && inviteDrawingOrigin) {
      handleFriendInviteBadgeDrawing(
        inviteUserOrigin,
        inviteDrawingOrigin,
      ).then(() => {
        handleFriendInviteBadge(inviteUserOrigin);
      });

      invitedHandled = true;
    }

    if (inviteUserOrigin && !invitedHandled) {
      handleFriendInviteBadge(inviteUserOrigin);
    }
  }, [inviteUserOrigin, inviteDrawingOrigin]);

  const onOptionClicked = (option: {
    name: string;
    value: Option;
  }) => (): void => {
    setSelectedOption(option.name);
    setIsDropdownOpen(false);
    if (option.value === null) {
      setDisplayedCourses(coursesSaved);
    } else {
      setDisplayedCourses(
        coursesSaved.filter((course) => course.status === option.value),
      );
    }
  };

  useEffect(() => {
    if (window.location.href
      .indexOf('urlRedirect=/parceiros/emprestimo-sim/pre-analise')
      !== -1
    ) {
      router.push('/parceiros/emprestimo-sim/pre-analise');
    }
  });

  useEffect(() => {
    const coursesWithProgressInfo = courses.map((course) => {
      const courseProgress = coursesProgressAllCourses.find(
        (toBeFound) => toBeFound.id === course.id,
      );
      return {
        ...course,
        status: courseProgress?.status ?? CourseProgressStatusEnum.NotStarted,
      };
    });
    setCoursesSaved(coursesWithProgressInfo);
    const unstartedCourses = coursesWithProgressInfo.filter(
      (course) => course.status === CourseProgressStatusEnum.NotStarted,
    );
    setDisplayedCourses(unstartedCourses);
  }, [courses, coursesProgress]);

  const displayBlockedCourseBanner = () => {
    const banner = document.getElementsByClassName('blockedCourseBanner');

    for (let item = 0; item < banner.length; item++) {
      banner[item].setAttribute('class', `${bannerStyles.block}`);
    }
  };

  return (
    <main>
      {hasHomeWarning && (
        <div className={styles.homeWrapper}>
          <div className={styles.homeWarning}>
            <img src="/assets/icons/alert.svg" alt="Alert icon" />
            <p>
              Olá {username}, enviamos o link desta oferta exclusiva para o seu
              e-mail. Acesse seu e-mail e clique no link para acessá-la. Caso
              não encontre nosso e-mail verifique também nas pastas de lixo
              eletrônico e spam.
            </p>
          </div>
        </div>
      )}
      <BodyHeader />
      {!loading && coursesProgress.length > 0 && (
        <article
          className={Utils.combineStyles(
            styles.coursesInProgress,
            styles.bodyPadding,
          )}
        >
          <DeviceComponent
            mobileComponent={
              <p className={styles.continueTitle}>Continue de onde parou</p>
            }
            desktopComponent={(
              <h3
                className={Utils.combineStyles(
                  styles.desktopContinueTitle,
                  styles.continueTitle,
                )}
              >
                Continue de onde parou
              </h3>
            )}
          />
          <CoursesList courses={coursesProgress} />
        </article>
      )}

      {isUserWithOpenedEmprestimoSimProposal
        && (
        <article
          className={Utils.combineStyles(
            styles.bodyPadding,
          )}
        >
          <ProposalProcessWarning />
        </article>
        )}

      <article
        className={Utils.combineStyles(
          styles.selectorWrapper,
          styles.bodyPadding,
        )}
      >
        <DeviceComponent
          mobileComponent={
            <h3 className={styles.mobileCoursesTitle}>Cursos</h3>
          }
          desktopComponent={
            <h3 className={styles.desktopCoursesTitle}>Cursos</h3>
          }
        />

        <div
          className={styles.dropdownContainer}
          data-cy="DropdownWrapper"
          ref={wrapperRef}
        >
          <div
            className={styles.dropdownHeader}
            role="menuitem"
            tabIndex={0}
            onClick={toggleDropdown}
            onKeyDown={toggleDropdown}
            data-cy="DropdownHeader"
          >
            {selectedOption}
            <div className={styles.arrow}>
              {!isDropdownOpen ? (
                <Image
                  src="/assets/vector.svg"
                  alt="loading"
                  width={10}
                  height={10}
                  onClick={() => {
                    setIsDropdownOpen(false);
                  }}
                  onKeyDown={() => {
                    setIsDropdownOpen(false);
                  }}
                />
              ) : (
                <Image
                  src="/assets/vector-up.svg"
                  alt="loading"
                  width={10}
                  height={10}
                  onClick={() => {
                    setIsDropdownOpen(false);
                  }}
                  onKeyDown={() => {
                    setIsDropdownOpen(false);
                  }}
                />
              )}
            </div>
          </div>
          {isDropdownOpen && (
            <div
              className={styles.dropdownListContainer}
              data-cy="DropdownListWrapper"
            >
              {isMobile && (
                <Modal show={isDropdownOpen} toggleModal={setIsDropdownOpen}>
                  <ul
                    className={styles.dropdownListMobile}
                    data-cy="DropdownListMobile"
                  >
                    <li
                      className={styles.dropdownListItemFixed}
                      role="menuitem"
                      onClick={() => {
                        setIsDropdownOpen(false);
                      }}
                      onKeyDown={() => {
                        setIsDropdownOpen(false);
                      }}
                    >
                      <Image
                        src="/assets/chevron-left-gray.svg"
                        alt="Voltar"
                        width={12}
                        height={12}
                      />
                    </li>
                    {StatusFilterOptions.map((option) => (
                      <li
                        className={styles.dropdownListItem}
                        role="menuitem"
                        onClick={onOptionClicked(option)}
                        onKeyDown={onOptionClicked(option)}
                        key={`desk${option.name}`}
                      >
                        {option.name}
                      </li>
                    ))}
                  </ul>
                </Modal>
              )}
              {!isMobile && (
                <ul className={styles.dropdownList} data-cy="DropdownList">
                  {StatusFilterOptions.map((option) => (
                    <li
                      className={styles.dropdownListItem}
                      role="menuitem"
                      onClick={onOptionClicked(option)}
                      onKeyDown={onOptionClicked(option)}
                      key={`mobile${option.name}`}
                    >
                      {option.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
      </article>
      <div className={`blockedCourseBanner ${bannerStyles.none}`}>
        <div className={`${bannerStyles.blockedCourseBanner}`}>
          <div className={bannerStyles.blockedCourse}>
            <div className={bannerStyles.imageAndDescriptionWrapper}>
              <img src="/assets/images/dudinhaContinueOnApp.svg" alt="Dudinha continue no app" />
              <div className={bannerStyles.descriptionWrapper}>
                <h1>O Membros virou App!</h1>
                <h2>Baixe o nosso <b>aplicativo</b> para <br /> assistir nossos cursos e participar <br /> dos nossos sorteios.</h2>
              </div>
            </div>
            <div className={bannerStyles.availableOnGooglePlay}>
              <div className={bannerStyles.blockedCourseHeader}>
                <img src="/assets/logo-plusdin-white.svg" alt="Logo Plusdin" className={bannerStyles.logoPlusdin} />
              </div>
              <a
                href="https://tracker.plusdin.com.br/PtrPMd"
                className={bannerStyles.googlePlay}
                target="_blank"
                rel="noreferrer"
                title="Obter aplicativo na Play Store"
              >
                <img src="/assets/available-on-google-play.svg" alt="Google Play" className={bannerStyles.googlePlay} />
              </a>
              <img src="/assets/images/qrcode-app.png" alt="Google Play QR Code" className={bannerStyles.qrCode} />
            </div>
          </div>
        </div>
      </div>
      <article
        className={Utils.combineStyles(
          styles.gridClassCourses,
          styles.bodyPadding,
        )}
        data-cy="CoursesRow"
      >
        {displayedCourses.map((course) => (
          course.id?.toString() === '1'? (
            <a key={`cc-${course.title}`} href={`courses/${course.id}`}>
              <CourseCard {...parseCourseProps(course)} />
            </a>
          ) : (
            <a key={`cc-${course.title}`} href="#" onClick={displayBlockedCourseBanner}>
              <CourseCard {...parseCourseProps(course)} />
            </a>
          )
        ))}
      </article>
    </main>
  );
}

export const getStaticProps: GetStaticProps = async () => {
  const courses = await CoursesClient.getCourses();
  return {
    props: { courses },
    revalidate: 120,
  };
};
