import React, { useRef, useEffect } from 'react';

const useClickOutside = (func : (x : boolean) => void) : React.MutableRefObject<any> => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        func(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return ref;
};

export default useClickOutside;
