import { get } from 'idb-keyval';
import { useRouter } from 'next/router';
import Button from '@components/Button';
import { getSession } from 'next-auth/client';
import styles from '../../styles/integrations/ProposalProcessWarning.module.scss';

const ProposalProcessWarning = () => {
  const router = useRouter();

  const redirectUserToProposalPage = async () => {
    const preAnalisysStepData = await get('preAnalisisData');
    const simulationStepData = await get('simulationData');
    const captureStepOneData = await get('captureStepOneData');
    const captureStepTwoData = await get('captureStepTwo');
    const proposalResultStepData = await get('proposalResult');
    const session = await getSession();
    const currentStep = session.emprestimoSimCurrentStep;

    switch (currentStep) {
      case 'simulacao':
        if (preAnalisysStepData && !simulationStepData && !proposalResultStepData) {
          router.push('/parceiros/emprestimo-sim/simulacao');
        }
        break;
      case 'captacao':
        if (preAnalisysStepData && simulationStepData && !captureStepOneData && !captureStepTwoData && !proposalResultStepData) {
          router.push('/parceiros/emprestimo-sim/resultado-simulacao');
        }
        if (preAnalisysStepData && simulationStepData && !captureStepOneData && !captureStepTwoData && !proposalResultStepData) {
          router.push('/parceiros/emprestimo-sim/captacao/sobre-voce');
        }
        if (preAnalisysStepData && simulationStepData && captureStepOneData && !captureStepTwoData && !proposalResultStepData) {
          router.push('/parceiros/emprestimo-sim/captacao/endereco');
        }
        if (preAnalisysStepData && simulationStepData && captureStepOneData && captureStepTwoData && proposalResultStepData) {
          router.push('/parceiros/emprestimo-sim/captacao/finalizacao');
        }
        break;
      default:
        router.push('/parceiros/emprestimo-sim/pre-analise');
        break;
    }
  };

  return (
    <div className={styles.emprestimoSimWarningContainer}>
      <div className={styles.contentContainer}>
        <img className="loan-logo" alt="Empréstimo SIM" src="/assets/partners/emprestimo-sim-logo-warning-small.svg" width={83} height={36} />

        <p>Você iniciou o preenchimento de formulário para
          solicitar um <strong>Empréstimo Pessoal Sim</strong>,
          clique no botão para retomar o preenchimento.
        </p>

        <Button
          className={styles.button}
          disabled={false}
          onClick={redirectUserToProposalPage}
          type="button"
        >
          Continuar solicitação
        </Button>
      </div>
    </div>
  );
};

export default ProposalProcessWarning;
