import client from './client';

const COURSES_ENDPOINT = '/courses';
const COURSE_ENDPOINT = (id: number | string): string => `/courses/${id}/no-auth`;
const VIDEO_ENDPOINT = (
  id: number | string,
  moduleId: number | string,
  videoId: number | string,
): string => `/courses/${id}/modules/${moduleId}/videos/${videoId}`;

interface Instructor {
  name: string;
  imageUrl: string;
}

export interface Video {
  id: number;
  title: string;
  description: string;
  lengthInSeconds: number;
  threshold: number;
  resourceUrl: string;
}

interface VideoDetails {
  id: number;
  moduleId: number;
}

export interface VideoUser {
  videoId: number;
  title: string;
  courseTitle: string;
  resource: string;
  nextVideo?: VideoDetails;
  previousVideo?: VideoDetails;
}

export interface Module {
  id: number;
  title: string;
  description: string;
  videos: Video[];
}

export interface Course {
  id: number;
  title: string;
  description: string;
  imageUrl: string;
  totalTimeInSeconds: number;
  instructors: Instructor[];
  numberOfBadgesRequired: number;
  modules: Module[];
}

const getCourses = async (): Promise<Course[]> => {
  try {
    const courses = await client.get<Course[]>(COURSES_ENDPOINT);
    return courses.data;
  } catch (err) {
    return [];
  }
};

const getCourse = async (id: number | string): Promise<Course> => {
  try {
    const course = await client.get<Course>(COURSE_ENDPOINT(id));
    return course.data;
  } catch (err) {
    return {} as Course;
  }
};

const getVideo = async (
  id: number | string,
  moduleId: number | string,
  videoId: number | string,
): Promise<VideoUser> => {
  try {
    const video = await client.get<any>(VIDEO_ENDPOINT(id, moduleId, videoId));
    return video.data;
  } catch (err) {
    return {} as VideoUser;
  }
};

export default {
  getCourses,
  getCourse,
  getVideo,
};
