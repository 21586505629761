/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import styles from 'styles/ProgressBar.module.scss';

interface ProgressBarProps {
  progressInPercentage: number; // number between 0 and 1
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progressInPercentage }) => (
  <div className={styles.progressBarArea}>
    <div className={styles.progressBar}>
      <div
        style={{ width: `${100 * progressInPercentage}%` }}
        className={styles.progressBarContent}
      />
    </div>

    <span className={styles.progressBarPercent}>
      {100 * progressInPercentage}%
    </span>

  </div>
);

export default ProgressBar;
